<template>
  <label class="label">
    <input
      type="checkbox"
      role="switch"
      :checked="reverse ? !modelValue : modelValue"
      :disabled="disabled"
      @change="
        $emit(
          'update:modelValue',
          reverse ? !$event.target.checked : $event.target.checked
        )
      "
    />

    <span v-if="!!label" :class="textSize ? textSize : ''">{{ label }}</span>
  </label>
</template>
<script>
import { watch } from "vue";

export default {
  name: "InputSwitch",
  props: {
    label: {
      type: String,
      required: false,
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isToggleEvent: {
      type: Boolean,
      default: false,
    },
    textSize: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue", "updateData"],
  setup(props, { emit }) {
    watch(
      () => props.modelValue,
      (newVal) => {
        if (props.isToggleEvent) {
          emit("updateData", newVal);
        }
      }
    );
  },
};
</script>

<style scoped>
.label {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

[type="checkbox"] {
  appearance: none;
  position: relative;
  border: max(2px, 0.1em) solid #ecf1f4;
  border-radius: 1.25em;
  width: 48px;
  height: 24px;
  background-color: #ecf1f4;
}

[type="checkbox"]::before {
  content: "";
  position: absolute;
  left: -2px;
  top: -2px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transform: scale(0.8);
  transition: left 250ms linear;
  background-color: white;
}

[type="checkbox"]:checked {
  background-color: #8e1eff;
  border-color: #8e1eff;
}

[type="checkbox"]:checked::before {
  left: 22px;
}

[type="checkbox"]:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

[type="checkbox"]:disabled:before {
  background-color: #ecf1f4;
}

[type="checkbox"]:disabled + span {
  opacity: 0.7;
  cursor: not-allowed;
}
</style>

<template>
  <div :class="classObject">
    <div ref="sideModal" class="side-modal">
      <button-basic
        class="close-btn"
        bg-color="transparent"
        padding="0"
        @action="actions.closeModal()"
      >
        <template #icon>
          <close-icon width="24" height="24"></close-icon>
        </template>
      </button-basic>

      <div class="side-modal-header">
        <slot name="modalHeader"></slot>
      </div>

      <div class="side-modal-body-wrapper">
        <div class="side-modal-body-content">
          <slot name="modalBody"></slot>
        </div>
      </div>

      <div class="side-modal-footer">
        <slot name="modalFooter"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import CloseIcon from "@/components/console/icons/CloseIcon.vue";

export default {
  name: "BaseModalSide",
  components: { CloseIcon, ButtonBasic },
  props: {
    isDimmed: {
      type: Boolean,
      default: false,
    },
    autoHide: {
      type: Boolean,
      default: false,
    },
    styleClass: {
      type: String,
      default: "",
    },
  },
  emits: ["closeModal"],
  setup(props, { emit }) {
    const sideModal = ref();

    const classObject = computed(() => {
      let styleClass = props.styleClass;
      let result = { "side-modal-wrapper": props.isDimmed };
      if (styleClass) {
        result[styleClass] = true;
      }
      console.log(styleClass);
      console.log(result);
      return result;
    });

    onMounted(() => {
      setTimeout(() => {
        //todo 원인 파악
        if (sideModal.value.style) {
          sideModal.value.style.right = "0%";
        }
      }, 50);

      if (props.autoHide) {
        setTimeout(() => {
          window.addEventListener("click", closeModal);
        }, 50);
      }
    });

    onBeforeUnmount(() => {
      window.removeEventListener("click", closeModal);
    });

    const closeModal = ($event) => {
      if (!sideModal.value.contains($event.target)) {
        emit("closeModal");
      }
    };

    const actions = {
      closeModal: () => {
        // break point
        if (window.innerWidth < 768) {
          sideModal.value.style.right = "-100%";
        } else {
          sideModal.value.style.right = "-28%";
        }
        setTimeout(() => {
          emit("closeModal");
        }, 200);
      },
    };

    return { actions, sideModal, classObject };
  },
};
</script>

<style scoped>
.side-modal-wrapper {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
}

.side-modal {
  width: 28%;
  max-width: 480px;
  min-width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: -100%;
  background-color: #ffffff;
  transition: right 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  z-index: 20;
}

.close-btn {
  position: absolute;
  right: 0;
  margin: 20px;
  width: 24px;
  height: 24px;
}

.side-modal-header {
  margin: 24px 24px 0 24px;
  border-bottom: solid 1px #e6eaef;
}

.side-modal-body-wrapper {
  flex: 1 1 auto;
  padding: 24px;
  overflow: hidden;
  height: 100%;
}

.side-modal-body-content {
  height: 100%;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.side-modal-body-content::-webkit-scrollbar {
  display: none;
}

.side-modal-footer {
  height: 100px;
  padding: 24px;
  border-top: solid 1px #e6eaef;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .side-modal {
    min-width: 420px;
    right: -28%;
  }

  .contents-upload-modal .side-modal {
    min-width: 700px;
  }
}
</style>

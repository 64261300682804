<template>
  <div>
    <label v-if="label" class="label sub-text-s2">
      {{ label }}
      <span v-if="required" class="text-purple-50">*</span>
      <span v-if="discount" class="discount">{{ discount }}</span>
    </label>

    <input
      class="b-text-1"
      :class="{ error: danger }"
      :placeholder="placeholder"
      :disabled="disabled"
      :value="state.inputMoney ? helper.priceFormat(state.inputMoney) : ''"
      type="text"
      @input="actions.setInputMoney($event)"
      @focusout="isFocusOutEvent ? actions.focusoutEvent() : ''"
    />
    <div v-if="danger && errorMessage">
      <span v-if="danger && errorMessage" class="text-red-50 sub-text-s3">
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>

<script>
import { reactive, watch } from "vue";

import helper from "@/helper";

export default {
  name: "InputMoney",
  props: {
    label: {
      type: String,
      required: false,
    },
    value: {
      type: [String, Number],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    danger: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      required: false,
    },
    discount: {
      type: String,
      required: false,
    },
    isFocusOutEvent: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      inputMoney: props.value ? props.value : "",
    });

    watch(
      () => props.value,
      (newVal) => {
        state.inputMoney = newVal;
      }
    );
    const actions = {
      setInputMoney: (e) => {
        state.inputMoney = e.target.value.replaceAll(",", "");
        emit("updateData", state.inputMoney);
      },
      focusoutEvent: () => {
        console.log(state.inputMoney);
        emit("updateData", state.inputMoney);
      },
    };
    return { actions, state, helper };
  },
};
</script>

<style scoped>
.label {
  margin-bottom: 4px;
}

.label .discount {
  padding-left: 6px;
  color: #ff550d;
}

input {
  border: 1px solid #e6eaef;
  border-radius: 4px;
  padding: 14px 16px;
  width: 100%;
}

input.error {
  border-color: #ff4646;
}
</style>

<template>
  <div class="membership-statics-card">
    <ul ref="membershipStatics">
      <li>
        <p class="sub-text-s3 text-gray-third">멤버십 가입 수</p>
        <div class="membership-count">
          <h2 class="text-gray-reverse">127,500</h2>
          <span class="increase sub-text-s3 text-red-50">
            <i class="fa-solid fa-caret-down"></i>5
          </span>
        </div>
      </li>
      <li>
        <p class="sub-text-s3 text-gray-third">팔로워 중 가입자 비율</p>
        <div class="membership-count">
          <h4 class="text-gray-reverse">208 / 5,000</h4>
          <span class="increase sub-text-s3 text-blue-50">
            <i class="fa-solid fa-caret-up"></i>30
          </span>
        </div>
      </li>
      <li>
        <p class="sub-text-s3 text-gray-third">누적 멤버십 금액</p>
        <div class="membership-count">
          <h4 class="text-gray-reverse">₩ 90,000,000</h4>
        </div>
      </li>
    </ul>
    <button class="fold-append-btn" @click="actions.toggleStatics()">
      <i
        class="fa-solid fa-angle-down text sub-title-s1 text-gray-reverse"
        :class="state.appendStatics ? 'fa-angle-up' : 'fa-angle-down '"
      ></i>
    </button>
  </div>
</template>

<script>
import { reactive, ref } from "vue";

export default {
  name: "MembershipStatics",
  setup() {
    const membershipStatics = ref();

    const state = reactive({
      pageLoading: false,
      appendStatics: false,
    });

    const actions = {
      toggleStatics: () => {
        if (!state.appendStatics) {
          membershipStatics.value.style.maxHeight = "462px";
          state.appendStatics = true;
        } else {
          membershipStatics.value.style.maxHeight = "241px";
          state.appendStatics = false;
        }
      },
    };

    return { state, actions, membershipStatics };
  },
};
</script>

<style src="./style.css" scoped></style>

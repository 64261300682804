<template>
  <button class="drop-down-button__item sub-text-s2" :style="{ color: color }">
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "ButtonDropdownItem",
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      default: "#5c5776",
    },
  },
};
</script>

<style scoped>
.drop-down-button__item {
  cursor: pointer;
  text-align: left;
  width: 100%;
  display: flex;
  padding: 0.5rem 0.875rem;
  border-radius: 0.2rem;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.drop-down-button__item:hover {
  background-color: #f5f7fa;
}
</style>

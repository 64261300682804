<template>
  <base-modal-side :is-dimmed="true" @closeModal="actions.closeModal()">
    <template #modalHeader>
      <div class="header">
        <h3 class="text-default">멤버십 추가</h3>
        <p class="text-gray-second sub-text-s3">
          이 곳은 멤버십 추가 디스크립션 영역입니다. 자유롭게 기재해주세요.
        </p>
      </div>
    </template>

    <template #modalBody>
      <input-basic
        class="membership-title__input"
        label="멤버십 등급 타이틀"
        placeholder="타이틀을 입력 해주세요."
        :required="true"
        :default-value="state.membershipForm.title"
        :max-length="20"
        :caption="`${state.membershipForm.title.length}/20`"
        :error-message="state.titleError"
        @updateData="(value) => actions.updateTitle(value)"
      ></input-basic>

      <input-money
        label="월 구독료 (원)"
        :value="state.membershipForm.price"
        placeholder="월 구독료를 입력하세요."
        @updateData="(value) => actions.updatePrice(value)"
      ></input-money>

      <div class="palette">
        <label>
          <span class="sub-text-s2 b-text-1">멤버십 색상</span>
        </label>
        <ul>
          <li>
            <div class="palette-item" :class="{ active: true }">
              <div class="inner" :style="{ backgroundColor: '' }">
                <hr />
              </div>
              <img class="active-check" src="/assets/images/svg/ic_check.svg" />
            </div>
          </li>
          <li>
            <div class="palette-item" :class="{ active: true }">
              <div class="inner" :style="{ backgroundColor: '#8F00FF' }"></div>
              <img class="active-check" src="/assets/images/svg/ic_check.svg" />
            </div>
          </li>
          <li>
            <div class="palette-item">
              <div class="inner" :style="{ backgroundColor: '#FF3C81' }"></div>
              <img class="active-check" src="/assets/images/svg/ic_check.svg" />
            </div>
          </li>
          <li>
            <div class="palette-item">
              <div class="inner" :style="{ backgroundColor: '#3A95FF' }"></div>
              <img class="active-check" src="/assets/images/svg/ic_check.svg" />
            </div>
          </li>
          <li>
            <div class="palette-item">
              <div class="inner" :style="{ backgroundColor: '#A8F942' }"></div>
              <img class="active-check" src="/assets/images/svg/ic_check.svg" />
            </div>
          </li>
          <li>
            <div class="palette-item">
              <div class="inner" :style="{ backgroundColor: '#FFC451' }"></div>
              <img class="active-check" src="/assets/images/svg/ic_check.svg" />
            </div>
          </li>
        </ul>
      </div>

      <input-select
        label="리워드"
        :required="true"
        :list="state.rewardSelectList"
        :selected-item="state.rewardSelectList[0]"
        @select="(reward) => actions.addReward(reward)"
      ></input-select>

      <hr />

      <div class="rewards">
        <div
          v-if="state.selectedRewards.length === 0"
          class="bg-gray-005 reward-item"
        >
          <span class="reward-item__handler">
            <img src="/assets/images/svg/ic_handler.svg" />
          </span>
          <span class="reward-item__text text-gray-second">
            현재 적용 중인 리워드가 없습니다.
          </span>
        </div>
        <ul>
          <li
            v-for="(reward, index) in state.selectedRewards"
            :key="`reward-item-${index}`"
          >
            <div class="bg-gray-005 reward-item">
              <span class="reward-item__handler">
                <img src="/assets/images/svg/ic_handler.svg" />
              </span>
              <span class="reward-item__text text-gray-second">
                {{ reward }}
              </span>
            </div>
          </li>
        </ul>
      </div>

      <input-basic
        label="등급 소개"
        placeholder="멤버십 등급 소개말을 적성해주세요."
        :default-value="state.membershipForm.description"
        :max-length="20"
        :caption="`${state.membershipForm.description.length}/20`"
        @updateData="(value) => actions.updateDescription(value)"
      ></input-basic>

      <div class="membership-grade">
        <label>
          <span class="sub-text-s2 b-text-1">멤버십 등급 이미지</span>
          <span class="text-purple-50">*</span>
        </label>
        <div
          ref="imageUploadButton"
          class="upload-section"
          @click="actions.openFileStack()"
        >
          <div>
            <i class="fa-solid fa-arrow-up-from-bracket text-default"></i>

            <div class="upload-section__text">
              <p class="text-gray-second sub-text-s3">이미지 업로드</p>
              <p class="c-text text-gray-second">
                * 권장 사이즈 : 가로 720px, 세로 720px 이상 <br />
                * 정방형이 아닌경우 좌우측의 이미지가 잘려 나올 수 있습니다.
              </p>
            </div>
          </div>
          <div ref="prevImage" class="prev-image"></div>
        </div>
      </div>

      <div class="limit-count">
        <div class="switch">
          <div>
            <label class="sub-text-s2"> 등급 가입인원 제한 설정 </label>
            <p class="text-gray-second sub-text-s3">
              멤버 인원을 제한할 경우에 설정하세요.
            </p>
          </div>
          <input-switch v-model="state.showLimitCountInput"></input-switch>
        </div>
        <div ref="limitCountInput" class="limit-count__input">
          <input-basic></input-basic>
        </div>
      </div>
    </template>

    <template #modalFooter>
      <div class="button-wrapper">
        <button-basic
          color="#0d0d0d"
          bg-color="#ECF1F4"
          text="취소"
          @action="actions.closeModal()"
        ></button-basic>
        <button-basic
          text="저장"
          @action="
            membership ? actions.editMembership() : actions.createMembership()
          "
        >
        </button-basic>
      </div>
    </template>
  </base-modal-side>
</template>

<script>
import BaseModalSide from "@/components/common/BaseModalSide.vue";
import { computed, reactive, ref, watch } from "vue";
import InputBasic from "@/components/console/inputs/InputBasic.vue";
import InputMoney from "@/components/console/inputs/InputMoney.vue";
import InputSelect from "@/components/console/inputs/InputSelect.vue";
import { useStore } from "vuex";
import FileStackService from "../../../../services/FileStackService";
import swal from "@/helper/swal";
import InputSwitch from "@/components/console/inputs/InputSwitch.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";

export default {
  name: "MembershipModal",
  components: {
    ButtonBasic,
    InputSwitch,
    InputSelect,
    InputMoney,
    InputBasic,
    BaseModalSide,
  },
  props: {
    membership: {
      type: Object,
      required: false,
    },
    rewards: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ["hideModal", "updating"],
  setup(props, { emit }) {
    const imageUploadButton = ref();
    const limitCountInput = ref();
    const prevImage = ref();

    const store = useStore();

    const state = reactive({
      membershipForm: {
        title: "",
        description: "",
        price: "",
        listPrice: 100000,
        rewardResourceIds: [],
        featuredImgFile: "",
        color: "",
        limitCount: 0,
      },
      titleError: "",
      rewardSelectList: [
        { value: "", text: "리워드를 추가하세요." }, // TODO ANDREW value 를 rewardResourceId 로 대체하기
        { value: "2", text: "리워드2" },
        { value: "3", text: "리워드3" },
      ],
      selectedRewardResourceIds: [],
      selectedRewards: computed(() => {
        return props.rewards.filter((item) => {
          return state.selectedRewardResourceIds.includes(item.resourceId);
        });
      }),
      showLimitCountInput: false,
    });

    watch(
      () => state.showLimitCountInput,
      (newValue) => {
        if (newValue) {
          limitCountInput.value.style.maxHeight = `${
            limitCountInput.value.scrollHeight + 1
          }px`;
        } else {
          limitCountInput.value.style.maxHeight = "0px";
        }
      }
    );

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
      editMembership: () => {
        console.log("Aaa");
      },
      createMembership: () => {
        store
          .dispatch("memberships/postMembership", state.membershipForm)
          .then(() => {
            swal.successToast("생성되었습니다.");
            emit("hideModal");
          });
      },
      updateTitle: (val) => {
        state.membershipForm.title = val;
      },
      updatePrice: (val) => {
        state.membershipForm.price = val;
        console.log(val);
      },
      updateDescription: (val) => {
        state.membershipForm.description = val;
      },
      addReward: (reward) => {
        state.selectedRewardResourceIds.push(reward.value);
      },
      openFileStack: () => {
        const fileStack = new FileStackService();
        // 이미지 비율 세팅
        fileStack.options.transformations.crop.aspectRatio = 1 / 1;

        fileStack.options.onFileUploadFinished = (fileMetaData) => {
          emit("updating");
          state.membershipForm.featuredImgFile = fileMetaData.url;
          prevImage.value.style.backgroundImage = `url(${state.membershipForm.featuredImgFile})`;
        };

        fileStack.open(fileStack.options);
      },
    };

    return { state, actions, imageUploadButton, limitCountInput, prevImage };
  },
};
</script>

<style src="./style.css" scoped></style>

<template>
  <div class="bg-gray-01 membership-item">
    <div class="handler">
      <img src="/assets/images/svg/ic_handler.svg" />
    </div>
    <div class="contents-section">
      <div class="membership-item__header">
        <span class="sub-title-s2">{{ membership.title }}</span>
        <input-switch
          v-model="state.toggleSwitch"
          class="switch-input"
        ></input-switch>
        <span>
          <button-dropdown distance="8">
            <template #button>
              <button-text padding="2px">
                <template #icon>
                  <more-verti-icon fill-color="#a8a3b9"></more-verti-icon>
                </template>
              </button-text>
            </template>
            <template #dropdownList>
              <ul>
                <li>
                  <button-dropdown-item
                    v-close-popper
                    text="메뉴1"
                  ></button-dropdown-item>
                </li>
                <li>
                  <button-dropdown-item
                    v-close-popper
                    text="메뉴2"
                  ></button-dropdown-item>
                </li>
              </ul>
            </template>
          </button-dropdown>
        </span>
        <span>
          <button-text padding="2px" @click="actions.toggleDetail()">
            <template #icon>
              <arrow-icon
                :direction="state.showDetail ? 'up' : 'down'"
              ></arrow-icon>
            </template>
          </button-text>
        </span>
      </div>

      <div ref="membershipDetail" class="membership-item__body">
        <div class="detail-section1">
          <avatar
            class="membership-img"
            size="lg"
            :avatar-img="membership.featuredImage"
          ></avatar>
          <p class="sub-text-s3 text-gray-second">멤버십 설명</p>
          <p class="b-text-3">{{ membership.description }}</p>
        </div>

        <div class="detail-section2">
          <div class="detail-section2-1">
            <p class="sub-text-s3 text-gray-second">월 구독료</p>
            <p class="sub-text-s1">{{ state.price }}</p>
          </div>

          <div class="detail-section2-2">
            <p class="sub-text-s3 text-gray-second">멤버 수 제한</p>
            <p class="sub-text-s1">{{ membership.limitCount }}</p>
          </div>
        </div>

        <div class="detail-section3">
          <p class="sub-text-s3 text-gray-second">리워드</p>
          <ul>
            <li>
              <div class="reward-item">
                <div class="reward-handler">
                  <img src="/assets/images/svg/ic_handler.svg" />
                </div>
                <div class="reward-content">
                  <span class="sub-text-s2">reward1</span>
                </div>
              </div>
            </li>
            <li>
              <div class="reward-item">
                <div class="reward-handler">
                  <img src="/assets/images/svg/ic_handler.svg" />
                </div>
                <div class="reward-content">
                  <span class="sub-text-s2">reward2</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputSwitch from "../../../../components/console/inputs/InputSwitch";
import { computed, reactive, ref } from "vue";
import ButtonDropdown from "../../../../components/console/buttons/ButtonDropdown";
import ButtonDropdownItem from "../../../../components/console/buttons/ButtonDropdownItem";
import Avatar from "../../../../components/console/avatars/Avatar";
import helper from "@/helper";
import ButtonText from "@/components/console/buttons/ButtonText.vue";
import MoreVertiIcon from "@/components/console/icons/MoreVertiIcon.vue";
import ArrowIcon from "@/components/console/icons/ArrowIcon.vue";

export default {
  name: "MembershipItem",
  components: {
    ArrowIcon,
    MoreVertiIcon,
    ButtonText,
    Avatar,
    ButtonDropdownItem,
    ButtonDropdown,
    InputSwitch,
  },
  props: {
    membership: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const membershipDetail = ref();

    const state = reactive({
      showDetail: false,
      price: computed(() => {
        return props.membership.price
          ? helper.priceFormat(props.membership.price)
          : "-";
      }),
      toggleSwitch: false, // 임시
    });

    const actions = {
      toggleDetail: () => {
        state.showDetail = !state.showDetail;
        if (state.showDetail) {
          membershipDetail.value.style.maxHeight = `${
            membershipDetail.value.scrollHeight + 1
          }px`;
          membershipDetail.value.classList.add("show");
        } else {
          membershipDetail.value.style.maxHeight = "0px";
          membershipDetail.value.classList.remove("show");
        }
      },
    };

    return { state, actions, membershipDetail };
  },
};
</script>

<style src="./style.css" scoped></style>

<template>
  <button
    class="tab-button sub-title-s1"
    :class="{ active: active, 'text-gray-third': !active }"
    :disabled="disabled"
    @click.stop="actions.submitRequest()"
  >
    <span>
      {{ text }}
    </span>

    <locked-icon
      v-if="locked"
      class="lock-icon"
      fill-color="#D0D5DE"
    ></locked-icon>
  </button>
</template>

<script>
import LockedIcon from "../icons/LockedIcon";

export default {
  name: "ButtonTab",
  components: { LockedIcon },
  props: {
    text: {
      type: String,
      required: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    locked: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["action"],
  setup(props, { emit }) {
    const actions = {
      submitRequest: () => {
        emit("action");
      },
    };

    return { actions };
  },
};
</script>

<style scoped>
.tab-button {
  border-radius: 0;
  padding: 10px 0;
  margin-right: 20px;
  margin-bottom: -1px;
}

.tab-button .lock-icon {
  margin-top: -3px;
}

.tab-button.active {
  border-bottom: solid 2px #0d0d10;
}
</style>

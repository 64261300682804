<template>
  <div class="page-container-new">
    <div class="lock-container">
      <!--  잠금 화면 -->
      <div class="lock-section">
        <h2 class="title">
          멤버십 서비스는 <br class="d-mo" />곧 오픈 예정입니다.<br />
          준비되면 알려드릴게요 :D
        </h2>
        <img class="image" alt="멤버십 잠금 이미지" />
      </div>

      <template v-if="state.isUnlock">
        <page-loading v-if="state.pageLoading"></page-loading>

        <div v-if="!state.pageLoading">
          <page-header
            title="구독 멤버십"
            description=""
            :border-bottom="false"
          >
          </page-header>

          <div class="membership-container">
            <membership-statics></membership-statics>

            <img
              class="banner"
              src="/assets/images/membership/membership_banner.png"
            />

            <div class="tab-buttons">
              <button-tab
                text="멤버십"
                :active="state.activeMembershipTab"
                @click="actions.setButtonTab('membership')"
              ></button-tab>
              <button-tab
                text="리워드"
                :active="state.activeRewardTab"
                @click="actions.setButtonTab('reward')"
              ></button-tab>
            </div>

            <div class="membership-body">
              <!--	멤버십  -->
              <div
                class="membership-section"
                :class="{ show: state.activeMembershipTab }"
              >
                <div class="description">
                  <h3>멤버십 관리</h3>
                  <p></p>
                </div>
                <div>
                  <div class="header">
                    <h4>전체 멤버십 등급 수</h4>
                    <span class="sub-title-s2 text-gray-third">0</span>
                    <button
                      class="text-purple-50"
                      @click="actions.openMembershipModal()"
                    >
                      + 멤버십 추가
                    </button>
                  </div>

                  <div
                    v-if="state.memberships && state.memberships.length === 0"
                    class="bg-gray-01 empty-card"
                  >
                    <p class="sub-text-s2 text-gray-second">
                      현재 생성된 멤버십 등급이 없습니다. <br />티어 생성 버튼을
                      통해 멤버십을 구성해보세요.
                    </p>
                    <button
                      class="sub-title-s1 bg-purple text-gray-reverse"
                      @click="actions.openMembershipModal()"
                    >
                      멤버십 추가하기 +
                    </button>
                  </div>

                  <div
                    v-if="state.memberships && state.memberships.length > 0"
                    class="membership-list__wrapper"
                  >
                    <ul>
                      <li
                        v-for="membership in state.memberships"
                        :key="`membership-${membership.resourceId}`"
                        class="membership-item"
                      >
                        <membership-item
                          :membership="membership"
                        ></membership-item>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!--	리워드  -->
              <div
                class="reward-section"
                :class="{ show: state.activeRewardTab }"
              >
                <div class="description">
                  <h3>리워드 관리</h3>
                  <p></p>
                </div>
                <div>
                  <div class="header">
                    <h4>전체 리워드 수</h4>
                    <span class="sub-title-s2 text-gray-third">0</span>
                    <button class="text-purple-50">+ 리워드 추가</button>
                  </div>

                  <div class="bg-gray-01 empty-card">
                    <p class="sub-text-s2 text-gray-second">
                      현재 생성된 리워드가 없습니다.<br />
                      리워드 생성 버튼을 통해 리워드를 구성해보세요.
                    </p>
                    <button class="sub-title-s1 bg-purple text-gray-reverse">
                      리워드 추가하기 +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <teleport :disabled="true">
        <membership-modal
          v-if="state.showMembershipModal"
          :reward-list="[]"
          @hideModal="actions.closeMembershipModal()"
        ></membership-modal>
      </teleport>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, reactive, ref } from "vue";
import PageHeader from "../../../components/console/headers/PageHeader";
import PageLoading from "../../../components/console/loadings/PageLoading";
import MembershipStatics from "./MembershipStatics/MembershipStatics";
import ButtonTab from "../../../components/console/buttons/ButtonTab";
import MembershipModal from "./MembershipModal/MembershipModal";
import { useStore } from "vuex";
import MembershipItem from "./MembershipItem/MembershipItem";
export default {
  name: "Memberships",
  components: {
    MembershipItem,
    MembershipModal,
    ButtonTab,
    MembershipStatics,
    PageLoading,
    PageHeader,
  },
  setup() {
    const membershipStatics = ref();
    const store = useStore();

    const state = reactive({
      pageLoading: false,
      activeMembershipTab: true,
      activeRewardTab: false,
      showMembershipModal: false,
      memberships: computed(() => {
        return store.getters["memberships/memberships"];
      }),
      isUnlock: false,
    });

    onBeforeMount(() => {
      state.pageLoading = true;
      store.dispatch("memberships/getMemberships").then(() => {
        state.pageLoading = false;
      });
    });

    const actions = {
      setButtonTab: (tabName) => {
        if (tabName === "membership") {
          state.activeMembershipTab = true;
          state.activeRewardTab = false;
        }
        if (tabName === "reward") {
          state.activeRewardTab = true;
          state.activeMembershipTab = false;
        }
      },
      openMembershipModal: () => {
        state.showMembershipModal = true;
      },
      closeMembershipModal: () => {
        state.showMembershipModal = false;
      },
    };

    return { state, actions, membershipStatics };
  },
};
</script>

<style src="./style.css" scoped></style>
